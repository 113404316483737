let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-qberisktoesg-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-qberisktoesg-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://6trwr5jdqzezvgbsaze7zmkjyi.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://ji6virtdp9.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://yima6yxk19.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.qberisktoesg.forwoodsafety.com",
        WEBSOCKET: "wss://vrob03hdgk.execute-api.us-west-2.amazonaws.com/staging"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_FbavgIkCH",
        IDENTITY_POOL_ID: "us-west-2:7edb7718-4b56-4fe4-8a92-21156e0d4270",
        USERPOOL_HOSTED_DOMAIN: "forwood-qberisktoesg-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.qberisktoesg.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.qberisktoesg.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::732268466009:role/staging-CA"
    },
    samlProvider: {
      NAME: '',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.36.1",
        HOSTNAME: "id.staging.qberisktoesg.forwoodsafety.com",
        ENV_NAME: "staging",
        COOKIE_DOMAIN: ".staging.qberisktoesg.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "37318c47-b76a-46c5-be02-ff0147deb930",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.qberisktoesg.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
